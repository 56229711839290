import React, { Component } from 'react'
import Auxiliar from '../../cao/Auxiliar';
import './BarraMenuMDB.css'
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem } from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { Link, animateScroll as scroll } from "react-scroll";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";

export default class BarraMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      //isWideEnough: false,

    };
    this.onClick = this.onClick.bind(this);
    this.handlerSize = this.handlerSize.bind(this);
    window.onmousewheel=()=>{
      this.setState({
        collapse: false,
      })
    }
    window.ondrag=()=>{
      this.setState({
        collapse: false,
      })
    }
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
    
  }

  handlerSize() {
    if (window.innerWidth <= 500)
      this.onClick()

  }
   

  render() {
    return (
      <ReactScrollWheelHandler
            upHandler={() => this.setState({
              collapse: false,
            })}
            downHandler={() => this.setState({
              collapse: false,
            })}>

      <Auxiliar>
        <Router >
          
            <MDBNavbar fixed="top" dark expand="md" scrolling transparent>
              <MDBNavbarBrand href="/">
              <Link
                      className="navbar-brand"
                      activeClass="active"
                      to="intro"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={600}
                       ><i className="fas fa-braille fa-lg" ></i>
                      <strong>FUTURO DIGITAL</strong></Link>
               
              </MDBNavbarBrand>
              {!this.state.isWideEnough && <MDBNavbarToggler onClick={this.handlerSize} />}
              <MDBCollapse isOpen={this.state.collapse} navbar>
                <MDBNavbarNav right>
                  <MDBNavItem active>
                    <Link
                      activeClass="active"
                      to="intro"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={600}
                      onClick={this.handlerSize}>Home</Link>

                  </MDBNavItem>
                  <MDBNavItem>
                    <Link
                      activeClass="active"
                      to="Servicios"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={600}
                      onClick={this.handlerSize}>Servicios</Link>
                  </MDBNavItem>
                  <MDBNavItem>
                    <Link
                      activeClass="active"
                      to="About"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={600}
                      onClick={this.handlerSize}>Acerca de</Link>
                  </MDBNavItem>
                  <MDBNavItem>
                    <Link
                      activeClass="active"
                      to="Portafolio"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={600}
                      onClick={this.handlerSize}>Portafolio</Link>
                  </MDBNavItem>
                  <MDBNavItem>
                    <Link
                      activeClass="active"
                      to="contact"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={600}
                      onClick={this.handlerSize}>Contacto</Link>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBNavbar>
          
        </Router>
      </Auxiliar>
      </ReactScrollWheelHandler>
    )
  }
}
