import React, { Component } from 'react'
import Auxiliar from '../cao/Auxiliar';
import Header from './Secciones/Header/Header';
import About from './Secciones/About/About';
import Servicios from './Secciones/Servicios/Servicios';
import Portafolio from './Secciones/Portafolio/Portafolio'
import Contacto from './Secciones/Contacto/Contacto';
import BarraMenuMDB from '../components/BarraMenuMDB/BarraMenuMDB';
import ReactScrollWheelHandler from "react-scroll-wheel-handler";



export default class Index extends Component {

    constructor(props) {
        super(props)
        this.scrollElement = React.createRef()
    }
    render() {
        return (
            <ReactScrollWheelHandler
                upHandler={() => this.scrollElement.current.setState({
                    collapse: false,
                })}
                downHandler={() => this.scrollElement.current.setState({
                    collapse: false,
                })}>
                <Auxiliar>
                    <BarraMenuMDB ref={this.scrollElement}/>
                    <Header />
                    <Servicios></Servicios>
                    <About></About>
                    <Portafolio></Portafolio>
                    <Contacto></Contacto>
                </Auxiliar>
            </ReactScrollWheelHandler>
        )
    }
}
