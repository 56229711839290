import React, { Component } from 'react'
import ezBuild from '../../../assets/images/az-logo.png'
import asClick from '../../../assets/images/Icono App AsClick.png'
import at from '../../../assets/images/logo.png'
import sifuturo from '../../../assets/images/sifuturo-sin-fondo.png'
import aws from '../../../assets/images/aws.jpg'
import odoo from '../../../assets/images/OE2014_Odoo-1200x911.png'
import $ from 'jquery'
import Filterizr from 'filterizr'
import './Lightbox.css'
import { MDBContainer, MDBRow } from "mdbreact"
import './Portafolio.css'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGooglePlay,
  faAppStore
} from "@fortawesome/free-brands-svg-icons";


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
  },
}))(MuiDialogActions);
export default class Galeria extends Component {
  state = {
    photoIndex: 0,
    open: false,
    titulo: "",
    images: [
      ezBuild,
      asClick,
      at,
      sifuturo,
      aws,
      odoo
    ],
    names: [
      "ezBuild",
      "asClick",
      "Amarillo Taxi",
      "Si Futuro",
      "AWS",
      "odoo"
    ],
    des: "Descripcion",
    linkAndroid: "",
    descarga: "",


  }

  handleSelection = () => {
    $('.simplefilter li').removeClass('active');
    $('.simplefilter li').first().addClass('active');


    var filterizd = $('.filtr-container').filterizr();

    filterizd.filterizr('sort', 'title', 'asc');

  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });

  };

  handleClose = () => {
    this.setState({ open: false });
  };

  showDialog = (title, des) => {
    return (
      console.log("prueba")
    );
  }

  render() {
    Filterizr.installAsJQueryPlugin($);

    $(document).ready(function () {

      $('.simplefilter li').click(function () {
        $('.simplefilter li').removeClass('active');
        $(this).addClass('active');
      });

      var filterizd = $('.filtr-container').filterizr();

      filterizd.filterizr('sort', 'title', 'asc');

    });

    const { names } = this.state;



    return (
      <div>
        <div className="Contenido" id="Portafolio">
          <MDBContainer  >

            <div className="mdb-lightbox p-3">
              <MDBRow style={{ justifyContent: "center" }} >
                <h2 className="h1 text-center text-uppercase  pt-5 mb-3 ">NUESTRO PORTAFOLIO</h2>
                <p >Dentro de nuestro portafolio encontrarás soluciones innovadores a actividades de la vida normal</p>
                <div className="port-gallery">
                  <div className="filterizr-control">
                    <ul className="simplefilter">
                      <li className="fltr-controls active" data-filter="all">
                        Todos</li>
                      <li className="fltr-controls" data-filter="1">
                        Nuestras Apps</li>
                      <li className="fltr-controls" data-filter="2">
                        Herramientas</li>
                      <li className="fltr-controls" data-filter="4">
                        Consultorías</li>
                    </ul>
                  </div>



                  <div className="filtr-container" >

                    <div className="col-xs-4 co-sm-4 col-md-3 col-lg-4 filtr-item" data-category="2,4" data-title="odoo" >
                      <figure className="snip0015" >
                        <img className="img-responsive" src={odoo} alt="odoo" />
                        <figcaption onClick={() =>
                          this.setState({
                            photoIndex: 5, open: true, titulo: "Odoo",
                            des:
                              <div className="Descripcion">
                                <p>
                                  Odoo, conocido anteriormente como OpenERP y anteriormente como TinyERP, es un software de ERP integrado.
                                </p>
                                <p>
                                  Cuenta con una versión 'comunitaria' de código abierto bajo licencia LGPLv3 y una versión empresarial bajo licencia comercial
                                que complementa la edición comunitaria con características y servicios comerciales y desarrollada por la empresa belga Odoo S.A.
                                  </p>
                                <p>
                                  Con más de 10000 aplicaciones, Odoo cubre todas sus necesidades empresariales con una solución de un solo paso: ya no se requieren interfaces de software diferentes. Las aplicaciones de Odoo se integran perfectamente entre sí, lo que permite automatizar por completo los procesos de su empresa.
                                  </p>
                              </div>,
                            descarga: ""
                          })
                        }>
                          <h3><span>{names[5]}</span></h3>
                          <p>Herramienta</p>
                        </figcaption>

                      </figure>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-md-3 col-lg-4 filtr-item" data-category="1" data-title="Si futuro" >
                      <figure className="snip0015">
                        <img className="img-responsive" src={sifuturo} alt="Sifuturo" />
                        <figcaption onClick={() =>
                          this.setState({
                            photoIndex: 3, open: true, titulo: "Sifuturo",
                            des:
                              <div className="Descripcion">
                                <p>
                                  Mesa de ayuda contará con una patalla de inicio, en esta se deberá ingresar un correo electrónico y contraseña válidos para tener acceso a la aplicación.
                                </p>
                                <p>
                                  En el Registro de incidente-Generación Ticket se podrá visualizar la lista de todos los incidentes registrados se podrá seleccionar un incidente específico para ver su información.
                                </p>
                                <p>
                                  Además, el personal encargado del Call-Center podrá generar un nuevo incidente, para esto debe seleccionar un cliente, su contacto correspondiente y el equipo afectado. También se deberá describir cual es la falla técnica. Los ticket tendrá un estado inicial de “nuevo” y una fecha de inicio la cual será la fecha del servidor.
                                </p>
                              </div>,
                            descarga: ""
                          })
                        }>
                          <h3><span>{names[3]}</span></h3>
                          <p>App móvil</p>
                        </figcaption>
                      </figure>

                    </div>

                    <div className="col-xs-4 col-sm-4 col-md-3 col-lg-4 filtr-item" data-category="2,4" data-title="aws" >
                      <figure className="snip0015">
                        <img className="img-responsive" src={aws} alt="aws" />
                        <figcaption onClick={() =>

                          this.setState({
                            photoIndex: 4, open: true, titulo: "AWS", des:
                              <div className="Descripcion">
                                <p>
                                  APN Consulting Partners son firmas de servicios profesionales que ayudan a los clientes de todos los tamaños a diseñar, construir, migrar y administrar sus cargas de trabajo y aplicaciones en AWS.
                              </p>
                                <p>
                                  Ya sea que esté comenzando a desarrollar su negocio o ampliando su práctica en AWS, APN es un excelente lugar para comenzar.
                              </p>
                              </div>,
                            descarga: ""
                          })

                        }>
                          <h3><span>{names[4]}</span></h3>
                          <p>Herramienta</p>
                        </figcaption>
                      </figure>
                    </div>

                    <div className="col-xs-4 col-sm-4 col-md-3 col-lg-4 filtr-item" data-category="1" data-title="ezBuild" >
                      <figure className="snip0015">
                        <img className="img-responsive" src={ezBuild} alt="ezBuild" />
                        <figcaption onClick={() =>
                          this.setState({
                            photoIndex: 0, open: true, titulo: "EZBuild", des:
                              <div className="Descripcion">
                                <p>
                                  Una herramienta novedosa que te permitirá diseñar, remodelar y estimar los costos de tu casa. Ya sea que estás pensado en construir una nueva casa o adecuar la que tienes, EZBuild te ayudará a hacerlo.
                                </p>
                                <p>
                                  EZBuild es un asesor personal que te ayudará con la estimación de costos, volúmenes de obra, combinación rápida de acabados, precios de productos, proveedores recomendados de acuerdo con tus gustos y  tu ubicación.
                                </p>
                                <p>
                                  EZBuild es una herramienta que Calcula volúmenes de obra de forma rápida y fácil. Te ayudará a llevar un mejor control de obra y a comprar las cantidades necesarias.
                                </p>
                                <p>
                                  Es una herramienta de bolsillo para profesionales y estudiantes del diseño y la construcción, pero también para gente común.
                                </p>
                                <p>
                                  EZBuild Premium tiene funciones técnicas que te permitirán: calcular pórticos, analizar y diseñar vigas de hormigón armado, pre dimensionar columnas, cimentaciones y muchas cosas más.
                                </p>
                                <p>
                                  EZBuild te ayudará a buscar los mejores productos de construcción, a los mejores profesionales de la industria y a obtener cotizaciones en segundos.
                                </p>
                                <p>
                                  Además, EZBuild cuenta con un convertidor de unidades que te sacará de cualquier apuro. Es fácil de usar y sobre todo muy útil ya que te permite realizar cálculos directamente sobre la barra de ingreso de números. ¡Pruébalo!
                                </p>
                              </div>,
                            linkAndroid: 'https://play.google.com/store/apps/details?id=io.ionic.ezbuild&hl=es',
                            descarga:
                              <div>
                                <div><h2 className="tituloDownload">Descarga la app</h2></div>
                                <DialogActions style={{ justifyContent: 'center', marginTop: '10px' }}>
                                  <a target="_blank" rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=io.ionic.ezbuild&hl=es'>

                                    <Button className="boton">
                                      Google Play
                                    <FontAwesomeIcon icon={faGooglePlay} size="2x" style={{ paddingLeft: '5px' }} />
                                    </Button>
                                  </a>
                                  <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/ec/app/ezbuild/id1452582901?l=en">

                                    <Button className="boton">
                                      App Store
                                    <FontAwesomeIcon icon={faAppStore} size="2x" style={{ paddingLeft: '5px' }} />
                                    </Button>
                                  </a>

                                </DialogActions>
                              </div>
                          })
                        }>
                          <h3><span>{names[0]}</span></h3>
                          <p>App móvil</p>
                        </figcaption>
                      </figure>
                    </div>


                    <div className="col-xs-4 col-sm-4 col-md-3 col-lg-4 filtr-item" data-category="1" data-title="asClick" >
                      <figure className="snip0015">
                        <img className="img-responsive" src={asClick} alt="asclick" />
                        <figcaption onClick={() =>
                          this.setState({
                            photoIndex: 1, open: true, titulo: "AsClick", des:
                              <div className="Descripcion">
                                <p>
                                  AsClick es un medio de pagos, que permite pagar con tarjetas de crédito y débito de casi todas las instituciones financieras del Ecuador.
                                </p>
                              </div>,
                            linkAndroid: 'https://play.google.com/store/apps/details?id=com.futurodigital.asclick&hl=es',
                            descarga:
                              <div>
                                <div><h2 className="tituloDownload">Descarga la app</h2></div>
                                <DialogActions style={{ justifyContent: 'center', marginTop: '10px' }}>
                                  <a target="_blank" rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=com.futurodigital.asclick&hl=es'>

                                    <Button className="boton">
                                      Google Play
                                    <FontAwesomeIcon icon={faGooglePlay} size="2x" style={{ paddingLeft: '5px' }} />
                                    </Button>
                                  </a>
                                  <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.futurodigital.asclick&hl=es">

                                    <Button className="boton">
                                      App Store
                                    <FontAwesomeIcon icon={faAppStore} size="2x" style={{ paddingLeft: '5px' }} />
                                    </Button>
                                  </a>

                                </DialogActions>
                              </div>
                          })
                        }>
                          <h3><span>{names[1]}</span></h3>
                          <p>App móvil</p>
                        </figcaption>
                      </figure>

                    </div>

                    <div className="col-xs-4 col-sm-4 col-md-3 col-lg-4 filtr-item" data-category="1" data-title="at" >
                      <figure className="snip0015" >
                        <img className="img-responsive" src={at} alt="Amarillo taxi" />
                        <figcaption onClick={() =>
                          this.setState({
                            photoIndex: 2, open: true, titulo: "AmarilloTaxi", des:
                              <div className="Descripcion">
                                <p>
                                  AmarilloTaxi es un medio de cobro, que permite a los taxistas cobrar con tarjetas de crédito y débito de casi todas las instituciones financieras del Ecuador.
                                </p>

                              </div>,
                            linkAndroid: 'https://play.google.com/store/apps/details?id=com.futurodigital.amarillotaxi&hl=es',
                            descarga:
                              <div>
                                <h1 className="tituloDownload">Descarga la app</h1>
                                <DialogActions style={{ justifyContent: 'center', marginTop: '10px' }}>
                                  <a target="_blank" rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=com.futurodigital.amarillotaxi&hl=es'>

                                    <Button className="boton">
                                      <div> Google Play</div>

                                      <FontAwesomeIcon icon={faGooglePlay} size="2x" style={{ paddingLeft: '5px' }} />
                                    </Button>
                                  </a>
                                  <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/ec/app/amarillotaxi/id1463608642?l=en">

                                    <Button className="boton">
                                      App Store
                                    <FontAwesomeIcon icon={faAppStore} size="2x" style={{ paddingLeft: '5px' }} />
                                    </Button>
                                  </a>

                                </DialogActions>
                              </div>
                          })
                        }>
                          <h3><span>{names[2]}</span></h3>
                          <p>App móvil</p>
                        </figcaption>
                      </figure>

                    </div>
                    <Dialog onEnter={this.handleSelection}

                      onClose={this.handleClose}
                      aria-labelledby="customized-dialog-title"
                      open={this.state.open}

                    >
                      <DialogTitle className="tituloD" id="customized-dialog-title" onClose={this.handleClose}>
                        {this.state.titulo}
                      </DialogTitle>
                      <DialogContent dividers>
                        <img src={this.state.images[this.state.photoIndex]} alt=""></img>
                        {this.state.des}
                        {/* <Typography gutterBottom>
                          <img src={this.state.images[this.state.photoIndex]} alt=""></img>
                          {this.state.des}
                        </Typography> */}

                      </DialogContent>
                      {this.state.descarga}
                    </Dialog>
                  </div>
                </div>

              </MDBRow>
            </div>

          </MDBContainer>
        </div>

      </div>

    )
  }
}
