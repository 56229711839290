import React from 'react';
import  './About.css';
import imagen from './about2.jpg';
import {Container, Row, Col} from 'react-bootstrap';

const About = () => {
    return (
            <div id="About" className="About">
                <div className="Title">
                    ACERCA DE NOSOTROS
                </div>
                <div className="container">
                    
                <Container>
                    <Row>
                        <Col >
                            <img src={imagen} alt=""/>
                        
                            <div className="div2">
                                <h4>¿Quiénes somos?</h4>
                                <p>Somos un equipo de profesionales con amplia experiencia en desarrollo de proyectos de 
                                    software en diversas arquitecturas empresariales.</p>
                                <h4>¿Qué hacemos?</h4>
                                <p>Desarrollamos soluciones digitales innovadoras para actividades de la vida diaria, 
                                    utilizando la Web y los dispositivos móviles como canales de interacción con dichas 
                                    soluciones.</p>
                                <h4>¿Por qué escogernos?</h4>
                                <p>Conocemos una amplia variedad de tecnologías, lo que nos permite recomendar las mejores 
                                    soluciones para su empresa.
                                </p>
                            </div>       
                        </Col>
                    </Row>
                </Container>
                    
                </div>
                
            </div>
    )
}

export default About
