import React from 'react'
import './Servicios.css'
import {Row,Col,Container} from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAndroid,
    faApple,    
  } from "@fortawesome/free-brands-svg-icons";

const Servicios = () => {
    return (
        <div id="Servicios" className="Servicios">
            <div className="Service-title">
                NUESTROS SERVICIOS
            </div>
            <Container>
            <Row>
                    <Col >
                    <i className="fa fa-desktop" aria-hidden="true"></i>
                        <p></p>
                        <h3>SOLUCIONES EMPRESARIALES</h3>
                        <p></p>
                        <p>Transformación Digital con las soluciones que sean adecuadas para dar un giro al negocio.</p>
                    </Col>
                   
                    <Col>
                    <Container>
                        <Row className="justify-content-md-center iconos" >
                                <Col  xs={2} sm={2} lg={2} md={2}><FontAwesomeIcon icon={faAndroid}></FontAwesomeIcon></Col>
                                <Col > </Col>
                                <Col ><FontAwesomeIcon icon={faApple}></FontAwesomeIcon></Col>
                        </Row>
                    </Container>
                       
                    
                        <h3>SOLUCIONES MÓVILES</h3>
                        <p></p>
                        <p>Análisis del negocio orientado a la implementación de soluciones móviles multiplataforma.</p>
                    </Col>
                    <Col>
                        <i className="fas fa-rocket"></i>
                        <p></p>
                            <h3>DESARROLLO DE SOFTWARE E INNOVACIÓN</h3>
                            <p></p>
                            <p>Soluciones de software buscando un enfoque de innovación.</p>
                    </Col>

                    <Col>
                        <i className="fas fa-cloud"></i>
                        <p></p>
                            <h3>NEGOCIOS EN LA NUBE</h3>
                            <p></p>
                            <p>Soluciones orientadas a servicios.</p>
                    </Col>
                   
                </Row>
            </Container>
            
        </div>
    )
}

export default Servicios
