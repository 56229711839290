import React, { Component } from 'react'
import './Contacto.css'
import SocialFollow from './SocialFollow'
import axios from 'axios'
import {Button} from 'mdbreact'
import $ from 'jquery'
import {Messages} from 'primereact/messages';




export default class Contacto extends Component {
    constructor(props){
        super(props);

        this.state = {
            nombre: '',
            email: '',
            mensaje:''
        };
        this.handleChange = this.handleChange.bind(this)
    }
    
    
    postDataHandler = () => {
        
      const data = {
        nombre: this.state.nombre,
        email: this.state.email,
        mensaje: this.state.mensaje  
      };
      console.log(this.state);
      axios.post('https://contacto-477e4.firebaseio.com/mensaje.json', data)
      .then(response => {
        if(this.state.nombre!==''&&this.state.email!==''&&this.state.mensaje!=='') {
            this.messages.show({ severity: 'success', summary: '¡ Mensaje Enviado !', detail: 'Gracias por tu comentario' });
            this.setState({nombre:'',email:'',mensaje:''})
        }else{
            this.messages.show({ severity: 'error', summary: '¡ Error !', detail: 'Debes llenar todos los campos' });
        }

      });
    }
    
    handleChange (e) {
        const name= e.target.name;
        const value=e.target.value;
        this.setState ( {
            [name] : value
        })
    };
    
    render() {
        $(document).ready(function() {
            $('#limpiar').click(function() {
              $('input[type="text"]').val('');
            });
          });
          
        return (
            <div id="contact" >
                <div className="text-center">
                    <div className="overlay">
                        <div className="container">
                            <div className="col-md-8 col-md-offset-2 section-title">
                                <h2>Mantente en contacto</h2>
                                <p>Envíanos tus comentarios y te contactaremos</p>
                            </div>
                            <div className="col-md-8 col-md-offset-2">
                                <form name="sentMessage" id="contactForm" noValidate>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" id="name" name= "nombre" value={this.state.nombre} className="form-control limpiar" placeholder="Nombre" required="required" aria-invalid="false" onChange={this.handleChange}></input>
                                                
                                                <p className="help-block text-danger"></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="email" id="email" name="email" value={this.state.email} className="form-control limpiar" placeholder="Email" required="required" onChange={this.handleChange}></input>
                                                
                                                <p className="help-block text-danger"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <textarea name="mensaje" value={this.state.mensaje} id="message" className="form-control limpiar" rows="4" placeholder="Mensaje" required onChange={this.handleChange}></textarea>
                                        
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div className="success"></div>
                                    <Messages ref={(el) => this.messages = el}></Messages>
                                    <Button id="limpiar" type="reset" onClick={this.postDataHandler} >Enviar mensaje</Button>
                                </form>
                                <div className="social">
                                    <SocialFollow />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="footer">
                    <div className="container text-center">
                        <div className="fnav">
                            <p>Copyright © 2019 Futuro Digital</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
